import React from 'react';


export default function ViewOptions({sortType, setSortType, hideChrono = false, hideRelease = false}) {

  const toggleDirection = (type, e) => {

    const direction = e.target.dataset.dir;

    if (direction === 'acs') {
      e.target.dataset.dir = 'desc';
      setSortType({'type':type,'direction':'desc'});
    } else {
      e.target.dataset.dir = 'acs';
      setSortType({'type':type,'direction':'acs'});
    }

  }

    return (
      <div className={`view-options view-options--${sortType.type}`}>
        <div className="view-options__title">Sort:</div>
        {hideChrono ? '' : <button type="button" onClick={(e) => toggleDirection("chrono", e)} className="view-options__btn view-options__btn--chrono">Chronological</button>}
        {hideRelease ? '' : <button type="button" onClick={(e) => toggleDirection("release", e)} className="view-options__btn view-options__btn--release">Released</button>}
        <button type="button" onClick={(e) => toggleDirection("alpha", e)} className="view-options__btn view-options__btn--alpha">Alphabetical</button>
        <button type="button" onClick={(e) => toggleDirection("length", e)} className="view-options__btn view-options__btn--length">Duration</button>
      </div>
    );
  }
  