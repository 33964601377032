import React from 'react';


export default function FilterOptions({filterType, setFilterType}) {

  const toggleFilter = (type, el) => {
    let tempfilterType = Object.assign({}, filterType);
    tempfilterType[type] = el.checked;
    setFilterType(tempfilterType);

    document.querySelectorAll('.binge-list__item').forEach((item) => {

      console.log(item.dataset.status);

      /*
      if (item.dataset.status === 'active') {
        console.log(item.dataset.status);
        item.querySelector('.binge-item-header > .form-switch > input').removeAttribute('disabled');
      } else {
        item.querySelector('.binge-item-header > .form-switch > input').setAttribute('disabled', 'disabled');
      }
      */

    });
  }

    return (
      <div className={`view-options view-options--filter`}>
        <div className="view-options__title">Filter:</div>

        <label className="view-options__label">
        <label className="form-switch">
              <input type="checkbox" name={`filter-movies`} onChange={(e) => toggleFilter('movies', e.target)} checked={ filterType.movies } />
              <i></i>
        </label>
        Movies
        </label>

        <label className="view-options__label">
        <label className="form-switch">
              <input type="checkbox" name={`filter-series`} onChange={(e) => toggleFilter('series', e.target)} checked={ filterType.series } />
              <i></i>
        </label>
        Series
        </label>
      </div>
    );
  }
  