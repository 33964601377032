export default function formatDuration(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);          
    const minutes = totalMinutes % 60;
    const hourLabel = hours > 1 ? 'hrs' : 'hr';
    const minutesLabel = minutes > 1 ? 'mins' : 'min';
    if ((hours > 0) && (minutes > 0)) {
        return `${hours} <span class="runtime-label">${hourLabel}</span> ${minutes} <span class="runtime-label">${minutesLabel}</span>`;
    } else if (hours > 0) {
        return `${hours} <span class="runtime-label">${hourLabel}</span>`;
    } else {
        if (minutes > 0) {
          return `${minutes} <span class="runtime-label">${minutesLabel}</span>`;
        } else {
          return 'No Items'; 
        }
    }
    
}
