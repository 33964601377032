import React from 'react';
import ViewOptions from './ViewOptions';
import FilterOptions from './FilterOptions';


export default function SectionTitle({title, style, filterType, sortType, setSortType, setFilterType, hideChrono, hideRelease}) {

  const showSort = (e) => {
    e.preventDefault();
    const sortPopup = document.querySelector('.section-title__sort-popup');

    if (sortPopup.classList.contains('show')) {
      e.target.classList.remove('active');
      sortPopup.classList.remove('show');
    } else {
      e.target.classList.add('active');
      sortPopup.classList.add('show');
    }
    
  };

  const showFilter = (e) => {
    e.preventDefault();
    const filterPopup = document.querySelector('.section-title__filter-popup');

    if (filterPopup.classList.contains('show')) {
      e.target.classList.remove('active');
      filterPopup.classList.remove('show');
    } else {
      e.target.classList.add('active');
      filterPopup.classList.add('show');
    }
    
  };

    return (
      <div className={`section-title section-title__${style}`}>
        <div className="section-title__text">{title}</div>
        <div>
          <button className="section-title__button" onClick={(e) => showSort(e)} type="button">

            <svg className="icon-sort" enableBackground="new 0 0 301.219 301.219" viewBox="0 0 301.219 301.219" xmlns="http://www.w3.org/2000/svg">
              <path d="m159.36 23.736v-10c0-5.523-4.477-10-10-10h-139.36c-5.523 0-10 4.477-10 10v10c0 5.523 4.477 10 10 10h139.36c5.523 0 10-4.477 10-10z"/>
              <path d="m130.59 66.736h-120.59c-5.523 0-10 4.477-10 10v10c0 5.523 4.477 10 10 10h120.59c5.523 0 10-4.477 10-10v-10c0-5.523-4.477-10-10-10z"/>
              <path d="m111.8 129.74h-101.8c-5.523 0-10 4.477-10 10v10c0 5.523 4.477 10 10 10h101.8c5.523 0 10-4.477 10-10v-10c0-5.523-4.477-10-10-10z"/>
              <path d="m93.025 199.74h-83.025c-5.523 0-10 4.477-10 10v10c0 5.523 4.477 10 10 10h83.025c5.522 0 10-4.477 10-10v-10c0-5.523-4.477-10-10-10z"/>
              <path d="m74.244 262.74h-64.244c-5.523 0-10 4.477-10 10v10c0 5.523 4.477 10 10 10h64.244c5.522 0 10-4.477 10-10v-10c0-5.523-4.477-10-10-10z"/>
              <path d="m298.29 216.88l-7.071-7.071c-1.875-1.875-4.419-2.929-7.071-2.929s-5.196 1.054-7.072 2.929l-34.393 34.393v-225.46c0-5.523-4.477-10-10-10h-10c-5.523 0-10 4.477-10 10v225.46l-34.393-34.393c-1.876-1.875-4.419-2.929-7.071-2.929s-5.196 1.054-7.071 2.929l-7.072 7.071c-3.904 3.905-3.904 10.237 0 14.142l63.536 63.536c1.953 1.953 4.512 2.929 7.071 2.929s5.119-0.976 7.071-2.929l63.536-63.536c3.905-3.905 3.905-10.237 0-14.141z"/>
            </svg>

            <svg className="icon-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">
              <polygon fill="black" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
            </svg>

          </button>

          {style === "secondary" ? 
            <button className="section-title__button" onClick={(e) => showFilter(e)} type="button">

            <svg className="icon-sort" xmlns="http://www.w3.org/2000/svg" width="35" height="20" viewBox="0 0 352 448">
              <path d="M350.75 73.75c2.5 6 1.25 13-3.5 17.5l-123.25 123.25v185.5c0 6.5-4 12.25-9.75 14.75-2 0.75-4.25 1.25-6.25 1.25-4.25 0-8.25-1.5-11.25-4.75l-64-64c-3-3-4.75-7-4.75-11.25v-121.5l-123.25-123.25c-4.75-4.5-6-11.5-3.5-17.5 2.5-5.75 8.25-9.75 14.75-9.75h320c6.5 0 12.25 4 14.75 9.75z"></path>
            </svg>

              <svg className="icon-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">
                <polygon fill="black" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
              </svg>

            </button>
          : ''}

          <div className="section-title__sort-popup">
            {sortType ? <ViewOptions sortType={sortType} setSortType={setSortType} hideChrono={hideChrono} hideRelease={hideRelease} /> : ''}
          </div>
          {style === "secondary" ? 
            <div className="section-title__filter-popup">
              <FilterOptions filterType={filterType} setFilterType={setFilterType}  />
            </div>
          : ''}
        </div>
      </div>
    );
  }
  